import React from 'react';
import styled from 'styled-components/macro';

const Line = styled.div`
    position: relative;
    overflow: hidden;
    width: 1px;
    height: 200px;
    min-height: 48px;
    border-bottom-style: none;
    margin: 36px;
`;

const LineInner = styled.div`
    position: absolute;
    left: 6px;
    top: 0%;
    right: auto;
    left: auto;
    z-index: 1;
    width: 1px;
    height: 200px;
    background-color: #000000;
`;

const VerticalLine = () => {

    return (
        <Line>
            <LineInner></LineInner>
        </Line>
    )
};

export default VerticalLine;
