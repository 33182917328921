import React from 'react';
import styled from 'styled-components/macro';
import usImage from '../../assets/images/us.jpg';
import usImageMobile from '../../assets/images/us-mobile.jpg';
import { breakpoints } from '../../styles/breakpoints';
import { SectionHeading, JumpToAnchor, FixedImage, ParaImage } from '../../styles';
import { colors } from '../../styles/variables';
import { darken } from 'polished'

const SectionAccomodation = styled.section`
    position: relative;
    z-index: 3;
    background: #F8F7ED;
    overflow: hidden;
    min-height: 100vh;
    text-align: center;
    font-family: 'Lexend', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledJumpToAnchor = styled(JumpToAnchor)`
    padding-top: 0;
`;

const StyledSectionHeading = styled(SectionHeading)`
    font-size: 32px;
`

const Eyebrow = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    padding-bottom: 10px;
    color: #000000;
`;

const SmallCopyLineTwo = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 24px;
    color: #000000;
    line-height: 21px;
`;

const Tel = styled.a`
    color: ${colors.tan};

    &:hover {
        color: ${darken(0.2, colors.tan)};
    }
`;

const TravelInfoWrapper = styled.div`
    display: flex;
    max-width: 1100px;
    flex-wrap: wrap;
    justify-content: center;

    @media (${breakpoints.tablet}) {
        margin: 50px;
    }
`;

const TravelInfo = styled.div`
    padding: 0 24px 24px;

    @media (${breakpoints.tablet}) {
        width: 50%;
    }
`;

const SmallHeading = styled.h3`
    font-family: 'Lexend', sans-serif;
    padding-bottom: 10px;
    padding-top: 30px;
    text-transform: uppercase;
    font-size: 12px;
    color: #000000;
    letter-spacing: 2px;
`;

const SmallCopy = styled.p`
    font-family: 'Lexend', sans-serif;
    padding-bottom: 10px;
    font-size: 14px;
    color: #000000;
    line-height: 20px;

    span {
        color: ${colors.tan};
    }
`;

const SmallerCopy = styled.p`
    font-family: 'Lexend', sans-serif;
    padding-bottom: 10px;
    font-size: 11px;
    color: #000000;
    line-height: 20px;

    span {
        color: ${colors.tan};
    }
`;

const HowToBook = styled.p`
    font-family: 'Lexend', sans-serif;
    padding-bottom: 10px;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid black;
    display: inline-block;
`;

const ExternalLink = styled.a`
    font-family: 'Lexend', sans-serif;
    text-transform: uppercase;
    font-size: 11px;
    color: ${colors.tan};
    letter-spacing: 2px;
    text-decoration: none;
    line-height: 22px;
    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
        color: ${darken(0.2, colors.tan)};
    }
`;

const HotelLinkWrapper = styled.div`
    margin-top: 25px;
    margin-bottom: 25px;
`;

const HotelList = styled.li`
    margin-bottom: 10px;
`

const ExternalHotelLink = styled.a`
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: #000000;
    letter-spacing: 2px;
    text-decoration: none;
    border-bottom: 1px solid black;
    line-height: 22px;
    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
        color: grey;
    }
`;

const OtherHotelList = styled.ul`
    margin-top: 35px;
`;

const Accomodation = () => {

    return (
        <>
        <StyledJumpToAnchor id="accomodation" />
        <SectionAccomodation>
            <Eyebrow>Where The Feck To Stay</Eyebrow>
            <StyledSectionHeading>Accomodation</StyledSectionHeading>
            <SmallCopyLineTwo>Stay where the Brides &amp; Bridal party are:</SmallCopyLineTwo>
            

            <TravelInfoWrapper>
                <TravelInfo>
                    <SmallCopy>The Brides, Bridal party &amp; family are staying at The Roydon Marina Lodges &amp; Hotel</SmallCopy>
                    <SmallCopy>It's a 10 min car journey to the wedding venue, and a 10 min walk to the pub for the day after the wedding. <br/> There is the option to stay in lodge cabins or in the hotel which are all on the same site.</SmallCopy>
                    <SmallCopy>There’s free parking and it’s 10 min walk from Roydon train station.</SmallCopy>
                    
                    <HowToBook>HOW TO BOOK</HowToBook>
                    <SmallCopy>To book please call <Tel href="tel: +441279792777">01279 792777</Tel> and mention <br/> code <span>“Abi &amp; Cara”</span> which gives you 10% off of the price.</SmallCopy>
                    
                    <HotelLinkWrapper>
                        <ExternalLink href="https://www.roydonmarinavillage.co.uk/" target="_blank">Roydon Marina Hotel website &gt;</ExternalLink>
                        <SmallerCopy>(Heads up, you can't book online as we've held all of the rooms so please give them a call)</SmallerCopy>
                    </HotelLinkWrapper>
                </TravelInfo>
            
                <TravelInfo>
                    <SmallHeading>Other Accomodation Options</SmallHeading>
                    <SmallCopy>Other options that are 20 min car journey from the wedding venue.</SmallCopy>
                    <OtherHotelList>
                        <HotelList><ExternalHotelLink href="https://www.marriott.co.uk/hotels/travel/stnch-cheshunt-marriott-hotel/" target="_blank">Cheshunt Marriot &gt;</ExternalHotelLink></HotelList>
                        <HotelList><ExternalHotelLink href="https://www.marriott.co.uk/hotels/travel/lonwa-waltham-abbey-marriott-hotel/" target="_blank">Waltham Abbey Marriot &gt;</ExternalHotelLink></HotelList>
                        <HotelList><ExternalHotelLink href="https://www.ihg.com/holidayinnexpress/hotels/gb/en/harlow/lonhu/hoteldetail" target="_blank">Holiday Inn Harlow &gt;</ExternalHotelLink></HotelList>
                        <HotelList><ExternalHotelLink href="https://www.premierinn.com/gb/en/hotels/england/essex/harlow/harlow.html" target="_blank">Premier Inn Harlow &gt;</ExternalHotelLink></HotelList>
                    </OtherHotelList>
                </TravelInfo>
                
            </TravelInfoWrapper>
            
            <FixedImage src={usImageMobile} alt={'us'} />
            <ParaImage image={usImage} />
            
        </SectionAccomodation>
        </>
    )
};

export default Accomodation;
