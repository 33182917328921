import React from 'react';
import styled from 'styled-components/macro';
import { SectionHeading, JumpToAnchor } from '../../styles';
import { colors } from '../../styles/variables';
import { breakpoints } from '../../styles/breakpoints';

import VerticalLine from '../VerticalLine/VerticalLine';

const SectionGifts = styled.section`
    position: relative;
    z-index: 3;
    background: #F8F7ED;
    overflow: hidden;
    min-height: 700px;
    text-align: center;
    padding: 24px;
    font-family: 'Lexend', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    @media ${breakpoints.tablet} {
        900px;
        padding: 24px;
        padding-top: 100px;
    }
`;

const Address = styled.p`
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 24px;
    color: #000000;
    line-height: 22px;
`;

const GiftPotTitle = styled.p`
    text-transform: uppercase;
    font-family: 'Lexend', sans-serif;
    padding-bottom: 10px;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid black;
    display: inline-block;
`;

const Details = styled.p`
    font-size: 18px;
    padding-top: 20px;
    color: #000000;

    span {  
        padding-top: 10px;
        color: ${colors.tan};
        display: block;
    }
`;

const Quote = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 17px;
    padding-bottom: 10px;
    padding-top: 35px;
    color: #000000;
    line-height: 22px;
    position: relative;
    font-family: 'Patrick Hand SC', cursive;
    max-width: 500px;

    &::before {
        content: '"';
        position: absolute;
        left: -14px;
        top: -2px;
        font-family: 'Secular One',sans-serif;
        font-size: 40px;
        transform: rotate(180deg);

        @media ${breakpoints.tablet} {
            left: -24px;
        }
    }

    &::after {
        content: ' " ';
        position: absolute;
        bottom: -16px;
        right: 0;
        font-family: 'Secular One', sans-serif;
        font-size: 40px;
    }
`;

const Gifts = () => {

    return (
        <>
        {/* eslint-disable-next-line */}
        <JumpToAnchor id="gifts"></JumpToAnchor>
        <SectionGifts>
            <SectionHeading>Gifts</SectionHeading>
            <Quote>The best things in life are free, but you can give them to the birds and the bees, we want your money</Quote>
            <Address>Joking :D ... we absolutely do not expect gifts, but if you would like to gift us then we'd love a little help towards our honeymoon.</Address>
            <Address>If you’d prefer, you can transfer directly to our gift pot from your bank or Monzo.</Address>

            <GiftPotTitle>Our Gift Pot Details:</GiftPotTitle>
            <Details>SC: <span>04-00-04</span></Details>
            <Details>AN: <span>54236513</span></Details>
            <Details>Reference: <span>'Wedding Gift'</span></Details>
            <VerticalLine />
        </SectionGifts>
        </>
    )
};

export default Gifts;
