import React from 'react';
import styled from 'styled-components/macro';
import locationImage from '../../assets/images/location.jpg';
import { Section, SectionHeading, JumpToAnchor, FixedImage, ParaImage } from '../../styles';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/variables';
import { darken } from 'polished';

const Eyebrow = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    padding-bottom: 10px;
    color: #000000;
`;

const StyledParaImage = styled(ParaImage)`
    background-size: contain;
`;

const Address = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 50px;
`;

const StyledJumpToAnchor = styled(JumpToAnchor)`
    padding: 0;
`;

const AddressLineTravel = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    padding: 16px;
`;

const TravelInfoWrapper = styled.div`
    display: flex;
    max-width: 1100px;
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: center;

    @media (${breakpoints.tablet}) {
        margin: 50px;
    }
`;

const TravelInfo = styled.div`
    padding: 24px;

    @media (${breakpoints.tablet}) {
        width: 50%;
    }
`;

const SmallHeading = styled.h3`
    font-family: 'Lexend', sans-serif;
    padding-bottom: 16px;
    text-transform: uppercase;
    font-size: 14px;
    color: #000000;
    letter-spacing: 2px;
`;

const SmallCopy = styled.p`
    font-family: 'Lexend', sans-serif;
    padding-bottom: 10px;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
`;

const ExternalLink = styled.a`
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: ${colors.tan};
    letter-spacing: 2px;
    text-decoration: none;
    line-height: 22px;
    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
        color: ${darken(0.2, colors.tan)};
    }
`;

const Location = () => {

    return (
        <>
            {/* eslint-disable-next-line */}
                <StyledJumpToAnchor id="location"></StyledJumpToAnchor>
                <Section>                
                    <Eyebrow>Where</Eyebrow>
                    <SectionHeading>Location</SectionHeading>
                    <Address>The Barns at Lodge Farm</Address>
                    <AddressLineTravel>Epping Road, Epping Green, Epping, Essex, CM16 6FS</AddressLineTravel>

                    <TravelInfoWrapper>
                        <TravelInfo>
                            <SmallHeading>Arriving by car</SmallHeading>
                            <SmallCopy>The postcode (CM16 6FS) to the venue doesn't always work in Sat Navs.<br /> So please enter <span>‘The Barns at Lodge Farm’</span> into Google Maps. 
                            <br /> <br />
                            Otherwise the next nearest postcode for sat navs is CM16 6PY.
                            </SmallCopy>
                        </TravelInfo>
                    
                        <TravelInfo>
                            <SmallHeading>Arriving by train</SmallHeading>
                            <SmallCopy>The nearest train stations are Broxbourne and Harlow. <br/><br/>The nearest tube station is Epping which is on the central line. <br/><br/>All will require a taxi from the station to the venue.</SmallCopy>       
                        </TravelInfo>
                    </TravelInfoWrapper>

                    <ExternalLink href="https://lodgefarmnazeing.co.uk/pages/how-to-find-us" target="_blank">More info on the venue website &gt;</ExternalLink>
                    
                    <FixedImage src={locationImage}></FixedImage>
                    <StyledParaImage image={locationImage}></StyledParaImage>

                </Section>
        </>
        
    )
};

export default Location;
