
export const colors = {
    cream: '#F8F7ED',
    black: '#000000',
    tan: '#C78746'
};

export const zIndex = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10
};
