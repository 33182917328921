import React from 'react';

import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import Nav from './components/Nav/Nav';
import Hero from './components/Hero/Hero';
import One from './components/One/One';
import Travel from './components/Location/Location';
import Timescale from './components/Timescale/Timescale';
import Accomodation from './components/Accomodation/Accomodation';
import Gifts from './components/Gifts/Gifts';
import Faq from './components/Faq/Faq';
import ToTop from './components/ToTop/ToTop';
import BoomBox from './components/BoomBox/BoomBox';
import Footer from './components/Footer/Footer';

import { colors } from './styles/variables';

const GlobalStyle = createGlobalStyle`
  ${reset};
  /* other styles */
  font-family: 'Cabin', sans-serif;

  body {
    background: ${colors.cream};
  }
`;

const HomePage = () => {
  
  return (
    <>
      <GlobalStyle />
      <div>
        <Hero />
        <Nav />
        <One />
        <Travel />
        <Timescale />
        <Accomodation />
        <Gifts />
        <Faq />
        <ToTop />
        <BoomBox />
        <Footer />
      </div>
    </>
  );
}

export default HomePage;
