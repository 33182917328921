import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ArrowSVG from '../../assets/svgs/down-arrow.svg';
import { Link } from 'react-scroll';
import { colors } from '../../styles/variables';

const ToTopWrapper = styled.div`
    position: fixed; 
    bottom: 20px; 
    right: 24px;
    z-index: 1000;
    cursor: pointer;
`;

const StyledArrowSVG = styled(ArrowSVG)`
    width: 25px;
    height: 25px;
    transform: rotate(180deg);
    z-index: 1000;
    
    path {
      fill: #000; 
    }

    &:hover {
        path {
        fill: ${colors.tan}; 
      }
    }
`;

export default function ToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <ToTopWrapper>
      {isVisible && (
        <Link to="top" smooth={true}>
          <StyledArrowSVG />
        </Link>
      )}
    </ToTopWrapper>
  );
}
