import styled from 'styled-components/macro';
import { breakpoints } from './breakpoints';
import { colors } from './variables';

export const Section = styled.section`
    position: relative;
    z-index: 3;
    background: ${colors.cream};
    overflow: hidden;
    min-height: 100vh;
    text-align: center;
    font-family: 'Lexend', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${breakpoints.tablet} {
        padding-bottom: 50px;
    }
`;

export const InnerContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

export const SectionHeading = styled.h2`
    text-transform: uppercase;
    font-size: 35px;
    color: ${colors.black};
    margin: 10px 35px 0 35px;

    @media ${breakpoints.tablet} {
        font-size: 56px;
        margin: 0;
    }
`;

export const JumpToAnchor = styled.a`
    background: #F8F7ED;
    display: block;
    z-index: 0;
    position: relative;
`;

export const ParaImage = styled.div` 
    width: 100vw;
    display: none;
    z-index: 5;
    margin: 100px auto 0;
    background-image: ${(props) => `url(${props.image})`};
    min-height: 500px; 
    background-attachment: fixed;
    background-position: center 120px;
    background-repeat: no-repeat;
    background-size: cover;

    @media ${breakpoints.tabletL} {
        display: block;
    }
`;

export const FixedImage = styled.img` 
    width: 100vw;
    min-height: 600px;
    display: block;
    z-index: 5;
    margin: 100px auto 0;
    object-fit: cover;

    @media ${breakpoints.tabletL} {
        display: none;
    }
`;
