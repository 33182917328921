import React from 'react';
import styled from 'styled-components/macro';
import { Section, SectionHeading, JumpToAnchor, InnerContainer } from '../../styles';
import VerticalLine from '../VerticalLine/VerticalLine';
import RingsImage from '../../assets/images/rings.png';
import PartyImage from '../../assets/images/party.png';
import CocktailImage from '../../assets/images/cocktail.png';
import MealImage from '../../assets/images/meal.png';
import { breakpoints } from '../../styles/breakpoints';

const TimingSmallCopy = styled.p`
    font-size: 14px;
    padding: 16px 30px 0;
    color: #000000;
    line-height: 22px;
`;

const TimingSmallerCopy = styled.p`
    font-size: 12px;
    padding: 6px 30px 16px;
    color: #000000;
    line-height: 22px;
`;

const IrishBand = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    padding: 0 24px 0;
    color: #000000;
    line-height: 22px;
`;

const HairOfDog = styled.p`
    margin-top: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    padding: 0 24px 0;
    color: #000000;
    line-height: 22px;
`;

const TextUnderTable = styled.p`
    font-size: 14px;
    padding: 10px 24px;
    color: #000000;
    line-height: 21px;
`;

const TextUnderTableFirst = styled.p`
    font-size: 14px;
    padding: 10px 24px;
    color: #000000;
    line-height: 21px;
    padding-top: 40px;
    
`;

const HeadingTwo = styled.h3`
    margin-top: 100px;
    text-transform: uppercase;
    font-size: 30px;
    color: #000000;
`;

const TimingsSection = styled(Section)`
    padding-top: 100px;
`;

const HeadingDayTwo = styled.h3`
    margin-top: 140px;
    text-transform: uppercase;
    font-size: 30px;
    color: #000000;
`;

const TimeTableWrapper = styled.div`
    display: flex;
    max-width: 900px;
    margin: 0 auto;
    padding: 16px;
    justify-content: center;
`;

const TitleWrapper = styled.div`
    max-width: 250px;
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
    width: 50%;
`;

const TitleWrapperDayTwo = styled.div`
    max-width: 250px;
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
`;

const TimeTableTitle = styled.h3`
    text-transform: uppercase;
    padding: 10px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 2px;
    line-height: 18px;
    max-width: 143px;
    margin: 0 auto;

    &:nth-of-type(0) {
        border-right: none;
    }

    &:nth-of-type(2) {
        border-left: none;
    }
`;

const Time = styled.p`
    text-transform: uppercase;
    padding: 10px;
    font-size: 25px;
    padding-top: 10px;
    text-align: center;
`;

const TimePm = styled.span`
    font-size: 12px;
`;

const TimelineWrapper = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
`;

const Line = styled.div`
    height: 480px;
    width: 3px;
    background-color: black;
    margin: 0 auto;
    position: relative;

    &::before {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #000;
        position: absolute;
        top: -8px;
        left: -3px;
    }

    &::after {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #000;
        position: absolute;
        bottom: -8px;
        left: -3px;
    }
`;

const WeDoWrapper = styled.div`
    Display: flex;
    flex-direction: column;
    margin-top: 50px;
`;

const WeDoTimeWrap = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    &:nth-child(2) {
        padding-top: 150px;

        &::before {
            transform: scale(0.18);
            background-repeat: no-repeat;
            content: url(${MealImage}); 
            position: absolute;
            left: 140px;

            @media ${breakpoints.tablet} {
                transform: scale(0.18);
                background-repeat: no-repeat;
                content: url(${MealImage}); 
                position: absolute;
                left: 278px;
            }
        }
    }

    &::before {
        transform: scale(.12);
        background-repeat: no-repeat;
        content: url(${RingsImage}); 
        position: absolute;
        left: 60px;

        @media ${breakpoints.tablet} {
            transform: scale(.12);
            background-repeat: no-repeat;
            content: url(${RingsImage}); 
            position: absolute;
            left: 200px;
        }
    }
`;

const WeDoTimeWrapOther = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-top: 100px;
    position: relative;

    &:nth-child(2) {
        padding-top: 150px;

        &::before {
            transform: scale(.07);
            background-repeat: no-repeat;
            content: url(${PartyImage});  
            position: absolute;
            left: -250px;

            @media ${breakpoints.tablet} {
                transform: scale(.07);
                background-repeat: no-repeat;
                content: url(${PartyImage});  
                position: absolute;
                right: 218px;
            }
        }
    }

    &::before {
        transform: scale(.14);
        background-repeat: no-repeat;
        content: url(${CocktailImage});  
        position: absolute;
        right: 100px;

        @media ${breakpoints.tablet} {
            transform: scale(.14);
            background-repeat: no-repeat;
            content: url(${CocktailImage});  
            position: absolute;
            right: 230px
        }
    }
`;

const TimeStamp = styled.div`
    padding: 10px;

    @media ${breakpoints.tablet} {
        padding: 20px;
    }
`;

const DashLineRigth = styled.p`
    margin-right: -10px;
`;

const DashLineLeft = styled.p`
    margin-left: -10px;
`;

const SubTimeText = styled.p`
    font-size: 10px;
`;

const Timescale = () => {

    return (
        <>
            <JumpToAnchor id="timescale" />
            <TimingsSection>
                <InnerContainer>
                    <SectionHeading>Timings</SectionHeading>
                    
                    <TimingSmallCopy>Please see arrival timings below:</TimingSmallCopy>

                    <HeadingTwo>Wedding Day</HeadingTwo>
                    <TimingSmallCopy>25<sup>th</sup> August 2022</TimingSmallCopy>
                    <TimeTableWrapper>
                        <TitleWrapper>
                            <TimeTableTitle>Day <br /> Guest Arrival</TimeTableTitle> 
                            <Time>12:30<TimePm>pm</TimePm></Time>
                        </TitleWrapper>
                        <TitleWrapper>
                            <TimeTableTitle>Evening Guest Arrival</TimeTableTitle>
                            <Time>7:00<TimePm>pm</TimePm></Time>
                        </TitleWrapper> 
                    </TimeTableWrapper>

                    <TimelineWrapper>
                        <WeDoWrapper>
                            <WeDoTimeWrap>
                                <TimeStamp>
                                    <p>12:45pm</p>
                                </TimeStamp>
                                <div>
                                    <p>We Do</p>
                                    <DashLineRigth>--------------</DashLineRigth>
                                    <SubTimeText>Ceremony</SubTimeText>
                                </div>
                            </WeDoTimeWrap>
                            <WeDoTimeWrap>
                                <TimeStamp>
                                    <p>3pm</p>
                                </TimeStamp>
                                <div>
                                    <p>We Eat</p>
                                    <DashLineRigth>--------------</DashLineRigth>
                                    <SubTimeText>Meal</SubTimeText>
                                </div>
                            </WeDoTimeWrap>
                        </WeDoWrapper>
                        <Line></Line>
                        <WeDoWrapper>
                            <WeDoTimeWrapOther>
                                <TimeStamp>
                                    <p>1:30pm</p>
                                </TimeStamp>
                                <div>
                                    <p>We Drink</p>
                                    <DashLineLeft>--------------</DashLineLeft>
                                    <SubTimeText>Drinks <br /> Reception</SubTimeText>
                                </div>
                            </WeDoTimeWrapOther>
                            <WeDoTimeWrapOther>
                                <TimeStamp>
                                    <p>7:00pm</p>
                                </TimeStamp>
                                <div>
                                    <p>We Party</p>
                                    <DashLineLeft>--------------</DashLineLeft>
                                    <SubTimeText>Reception</SubTimeText>
                                </div>
                            </WeDoTimeWrapOther>
                        </WeDoWrapper>
                    </TimelineWrapper>
                    <TextUnderTableFirst>The Wedding Reception Ends at <span>Midnight.</span></TextUnderTableFirst>
                    <TextUnderTable>Please book taxi's to collect you at 12:15am latest.</TextUnderTable>
                    <TextUnderTable>See local taxi details in the "more info" section below.</TextUnderTable>
                    
                    <HeadingDayTwo>Day Two</HeadingDayTwo>
                    <TimingSmallCopy>26<sup>th</sup> August 2022</TimingSmallCopy>
                    <TimingSmallCopy>If we're lucky enough to have you for two days we'd love you to join us at the pub.</TimingSmallCopy>
                    
                    
                    <TimeTableWrapper>
                        <TitleWrapperDayTwo>
                            <TimeTableTitle>Day Two @ <br /> The New Inn</TimeTableTitle> 
                            
                            <Time>1:00<TimePm>pm</TimePm></Time>
                            <IrishBand>Food, Beers &amp; music</IrishBand>
                        </TitleWrapperDayTwo>
                    </TimeTableWrapper>
                    <TimingSmallCopy>The New Inn, High Street, Roydon, Essex, CM19 5EE</TimingSmallCopy>
                    <TimingSmallerCopy>(This is within walking distance to our accommodation<br /> and not far from the wedding venue too.)</TimingSmallerCopy>
                    <HairOfDog>#HairOfTheDog</HairOfDog>
                    <TimingSmallCopy>We'll be providing a buffet so let us know if you'd like to join us.</TimingSmallCopy>
                </InnerContainer>
                <VerticalLine/>
            </TimingsSection>
        </>
        
    )
};

export default Timescale;
