import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import ArrowSVG from '../../assets/svgs/down-arrow.svg';
import { breakpoints } from '../../styles/breakpoints';
import { colors, zIndex } from '../../styles/variables';
import { Link } from 'react-scroll';
import BoomBoxImage from '../../assets/images/boombox.png';
import Poster from '../../assets/images/website.gif';
import video from '../../assets/vids/website.mp4';

const HeroSection = styled.section`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: ${zIndex.zero};
`;

const VideoContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    z-index: ${zIndex.zero};
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate3d(0px, 0px, 0px);
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: #000;
`;

const Source = styled.source`
    filter: blur(1px);
`

const TitleContainer = styled.div`
    position: fixed;
    bottom: 12vh;
    left: 50%;
    transform: translate(-50%);
    margin: -1.1618vw 0 0;
    color: #fff;
    z-index: ${zIndex.three};
    transition: color .3s ease;

    @media ${breakpoints.tabletL} {
        top: 50%;
        bottom: auto;
    }
`;

const Title = styled.h1`
    font-family: 'Lexend', sans-serif;
    font-size: 40px;
    letter-spacing: 4px;
    font-weight: 700;
    color: ${colors.cream};

    @media(${breakpoints.tablet}) {
        font-size: 80px;
    }
`;

const StyledArrowSVG = styled(ArrowSVG)`
    width: 20px;
    height: 20px;
    z-index: ${zIndex.one};
    margin-bottom: 40px;
    color: ${colors.cream};
    bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    padding: 20px;

    path {
        fill: ${colors.cream};
    }

    &:hover {
        cursor: pointer;
        
        path {
            fill: ${colors.tan};
        }
    }

    @media ${breakpoints.tabletL} {
        bottom: 10vh;
    }
`;

const Loading = styled.div`
    background-color: ${colors.cream};
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${zIndex.four};
    position: relative;
    flex-direction: column;
    transition: transform 1s;
`;

const StyledLoadingImage = styled.img`
    width: 80px;
    margin-bottom: 20px;
    animation: spin 0.8s ease-in-out 2s 12 backwards;

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        }
`;

const LoadingText = styled.p`
    font-family: 'Lexend', sans-serif;  
`;



const Hero = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

    }, [isLoading])

    return (
        <HeroSection id="top">
            { isLoading && 
                <Loading>
                    <StyledLoadingImage src={BoomBoxImage} alt={'loading'} />
                    <LoadingText>Loading...</LoadingText>
                </Loading>
            }
                <>
                    <TitleContainer>
                    <Title>WEDDING</Title>
                    </TitleContainer>
                    <VideoContainer>
                        <Video 
                            autoPlay 
                            muted 
                            loop 
                            playsInline 
                            poster={Poster}
                            onLoadedData={() => setIsLoading(false)}
                            >
                            <Source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </Video>
                    </VideoContainer>
                    <Link to="welcome" smooth={true}>
                        <StyledArrowSVG />
                    </Link>
                </>
        </HeroSection>
    )
};

export default Hero;
