import React, { useRef, useState, useEffect } from 'react';
import BoomBoxSrc from '../../assets/images/boombox.png';
// import SmileyImage from '../../assets/images/smiley.png';
import styled from 'styled-components/macro';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/play-pause.json';

import IncognitoAudio from '../../assets/audio/feeling.mp3';

import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/variables';

// let listOfTunes = [BobAudio, IncognitoAudio];

const BoomBoxImage = styled.img`
    z-index: 1000000;
    max-width: 55px;
    cursor: pointer;

    @media ${breakpoints.tablet} {
        max-width: 100px;
    }

    animation: ${({ isPlaying }) => isPlaying ? 'shake 0.4s infinite' : 'pulse 2s 3'};

    @-webkit-keyframes pulse {
        70% {
            filter: drop-shadow(1px 1px 0 ${colors.tan});
            drop-shadow(-1px -1px 0 ${colors.tan});
        }

        100% {
            filter: drop-shadow(5px 5px 0 ${colors.tan});
            drop-shadow(-5px -5px 0 ${colors.tan});
        }
    }

    @keyframes shake { 
        0% { transform:translate(0,0) }
        20% { transform:translate(2px,5px) }
        50% { transform:translateY(2px,5px) }
        100% { transform:translate(0,0) }
    } 
`;

// const Smiley = styled.img`
//     position: fixed;
//     z-index: ${zIndex.ten};
//     top: ${({ isPlaying }) => isPlaying ? '10px' :  '-100px'};
//     right: ${({ isPlaying }) => isPlaying ? '10px' :  '-100px'};
//     width: 50px;
//     animation: ${({ isPlaying }) => isPlaying ? 'smile 0.4s infinite' :  'none'};

//     @-webkit-keyframes smile { 
//         0% { transform:translate(0,0) }
//         20% { transform:translate(2px,5px) }
//         50% { transform:translateY(2px,5px) }
//         100% { transform:translate(0,0) }
//     } 

//     @keyframes smile { 
//         0% { transform:translate(0,0) }
//         20% { transform:translate(2px,5px) }
//         50% { transform:translateY(2px,5px) }
//         100% { transform:translate(0,0) }
//     }
// `;

const LottieWrapper = styled.div`
    cursor: pointer;
    z-index: 1000000;
    position: absolute;
    border-radius: 50%;
    background-color: #C78746;
    transition: transform 1s ease-in-out;
    left: 30px;

     &:hover {
        transform: scale(1.1);
    }

    @media ${breakpoints.tablet} {
        left: 74px;
        bottom: 71px;
    }
`;

const Vibes = styled.div`
    cursor: pointer;
    display: inline-flex;
    flex-direction: row-reverse;
    position: fixed;
    bottom: 24px;
    left: 10px;
    z-index: 1000000;
    transform: ${({ isVisible }) => isVisible ? 'translateX(0)' : 'translateX(-100px)'};
    transition: transform 1s ease-in-out;
    height: 80px;

    @media ${breakpoints.tablet} {
        left: 10px;
        transform: ${({ isVisible }) => isVisible ? 'translateX(0)' : 'translateX(-150px)'};
        height: 100px;
    }

    &:hover {
        transform: scale(1.05);
    }
`;

const Tap = styled.p`
    color: black;
    position: absolute;
    top: -23px;
    width: 100px;
    left: 0;
    font-family: 'Bungee Outline', cursive;
    z-index: 1000000;
    font-weight: 700;

    @media ${breakpoints.tablet} {
        top: -26px;
        left: 25px;
    }
`;

const BoomBox = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const audioRef = useRef();
    const playButtonRef = useRef();

    // const randomTune = listOfTunes[Math.floor(Math.random() * listOfTunes.length)];

    const defaultOptions = {
        loop: false,
        autoplay: false,
        animationData: animationData,
        initialSegment: [0,45],
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    useEffect(() => {

        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);

    }, []);

    const handleLottieClick = (e) => {
        if (audioRef.current !== null) {
            if (!isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }

        if (isPlaying) {
            playButtonRef.current.anim.playSegments([0,45], true);
        } else { 
            playButtonRef.current.anim.playSegments([45,90], true); 
        }

        setIsPlaying(!isPlaying);
    
    }

    return (
        <>
            <Vibes onClick={(e) => handleLottieClick(e)} isPlaying={isPlaying} isVisible={isVisible}>
                <LottieWrapper>
                    <Lottie
                        ref={playButtonRef}
                        options={defaultOptions}
                        height={50}
                        width={50}
                    />
                </LottieWrapper>
                <Tap>Tap for vibes</Tap>

                <BoomBoxImage src={BoomBoxSrc} alt={'music'} isPlaying={isPlaying} isVisible={isVisible} />
                <audio ref={audioRef}>
                    <source src={IncognitoAudio}></source>
                    {/* <source src="audio/beep.ogg"></source> */}
                    Your browser isn't invited for super fun audio time.
                </audio>
            </Vibes>
        </>

    );
};

export default BoomBox;
