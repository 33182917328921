import React from 'react';
import styled from 'styled-components/macro';
import { breakpoints } from '../../styles/breakpoints';
import { SectionHeading } from '../../styles/';

import VerticalLine from '../VerticalLine/VerticalLine';

const SectionOne = styled.section`
    position: relative;
    z-index: 3;
    background: #F8F7ED;
    overflow: hidden;
    min-height: 100vh;
    text-align: center;
    padding-top: 100px;
    font-family: 'Lexend', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${breakpoints.tablet} {
        padding-bottom: 50px;
        padding-top: 100px;
    }
`;

const Eyebrow = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    padding-bottom: 10px;
    color: #000000;
`;

const Date = styled.p`
    text-transform: uppercase;
    font-size: 42px;
    color: #000000;
    font-family: 'Bungee Outline', cursive;
    font-weight: 600;

    sup {
        vertical-align: super;
        font-size: 24px;
    }

    span {
        display: block;
    }
`;

const One = () => {

    return (
        <>
            <SectionOne id="welcome">
                <Eyebrow>Come and party with us</Eyebrow>
                <SectionHeading>Abi &amp; Cara's <br />Wedding</SectionHeading>
                <VerticalLine />
                <Eyebrow>Thursday</Eyebrow>
                <Date>25 . 08 . 22</Date>
                <VerticalLine />
            </SectionOne>
        </>
        
    )
};

export default One;
