import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { colors, zIndex } from '../../styles/variables';
import { breakpoints } from '../../styles/breakpoints';
import BurgerMenuImage from '../../assets/images/restaurant-menu.png';
import { Link } from "react-scroll";

const TopNav = styled.nav`
    display: none;

    @media ${breakpoints.tablet} {
        display: block;
        box-shadow: 29px 33px 30px 23px rgb(0 0 0 / 5%);
        position: sticky;
        z-index: ${zIndex.nine};
        left: 0;
        top: 0;
        background: ${colors.cream};
    }
`;

const MobileTopNav = styled.nav`
    position: fixed;
    z-index: ${zIndex.nine};
    left: 0;
    top: 0;
    background: ${colors.cream};
    transform: ${({ navIsOpen }) => navIsOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;

    @media ${breakpoints.tablet} {
        display: none;
    }
`;

const StyledBurgerMenuImage = styled.img`
    width: 40px;
    height: 40px;
`;

const ListItems = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    background: ${colors.cream};
    height: 100vh;
    width: 60%;

    @media ${breakpoints.tablet} {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: auto;
    }
`;

const MenuBackground = styled.div`
    background: rgba(0,0,0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${zIndex.four};
    visibility: ${({ navIsOpen }) => navIsOpen ? 'visible' : 'hidden'};
    opacity: ${({ navIsOpen }) => navIsOpen ? '0.9' : '0'};
    transition: opacity 0.3s ease-in-out;
`

const MobileListItems = styled.ul`
    margin-top: 70px;   
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-start;
    background: ${colors.cream};
    height: 100vh;
    width: 60%;

    @media ${breakpoints.tablet} {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
    }
`;

const ListItem = styled.li`
    padding: 16px 24px;
    width: 100%;

    @media ${breakpoints.tablet} {
        width: unset;
    }
`

const NavLink = styled(Link)`
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Lexend', sans-serif;

    &:hover {
        color: ${colors.tan};
        cursor: pointer;
    }

    .active {
        color: ${colors.tan};
    }
`

const BurgerIcon = styled.div`
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: ${zIndex.ten};
    background: rgba(248, 247, 237, 0.5);
    padding: 2px;
    border-radius: 3px;
    padding-top: 4px;
    padding-bottom: 1.2px;

    @media ${breakpoints.tablet} {
        display: none;
    }
`;

const MobileNavBackground = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
`;

const Nav = () => {
    const [navIsOpen, setNavIsOpen] = useState(false);
    const mobileNavRef = useRef();
    const mobileLinksRef = useRef();
    const burgerIconRef = useRef();

    const toggleNav = (e) => {

        if (mobileLinksRef.current.contains(e.target)) {
            setNavIsOpen(false);
            return;
        } else if (mobileNavRef.current.contains(e.target)) {
                setNavIsOpen(!navIsOpen);
            return;
          } else {
            setNavIsOpen(false);
          }
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", toggleNav);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", toggleNav);
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <>
            <TopNav>
                <ListItems>
                    <ListItem><NavLink to="welcome" activeClass="active" smooth={true} offset={-120}>Welcome</NavLink></ListItem>
                    <ListItem><NavLink to="location" activeClass="active" smooth={true} offset={-120}>Location</NavLink></ListItem>
                    <ListItem><NavLink to="timescale" activeClass="active" smooth={true} offset={-120}>Timings</NavLink></ListItem>
                    <ListItem><NavLink to="accomodation" activeClass="active" smooth={true} offset={-120}>Accomodation</NavLink></ListItem>
                    <ListItem><NavLink to="gifts" activeClass="active" smooth={true} offset={-120}>Gifts</NavLink></ListItem>
                    <ListItem><NavLink to="moreinfo" activeClass="active" smooth={true} offset={-120}>More Info</NavLink></ListItem>
                    <ListItem><NavLink to="rsvp" activeClass="active" smooth={true} offset={-120}>RSVP</NavLink></ListItem>
                </ListItems>
            </TopNav>
            <MenuBackground navIsOpen={navIsOpen}></MenuBackground>
            <MobileNavBackground ref={mobileNavRef}>
                <BurgerIcon ref={burgerIconRef} onClick={() => toggleNav}><StyledBurgerMenuImage src={BurgerMenuImage} alt={'menu icon'} /></BurgerIcon>
                <MobileTopNav navIsOpen={navIsOpen}>
                    <MobileListItems ref={mobileLinksRef} onClick={() => toggleNav}>
                        <ListItem><NavLink to="welcome" activeClass="active" smooth={true} offset={-50}>Welcome</NavLink></ListItem>
                        <ListItem><NavLink to="location" activeClass="active" smooth={true} offset={-50}>Location</NavLink></ListItem>
                        <ListItem><NavLink to="timescale" activeClass="active" smooth={true}>Timings</NavLink></ListItem>
                        <ListItem><NavLink to="accomodation" activeClass="active" smooth={true} offset={-50}>Accomodation</NavLink></ListItem>
                        <ListItem><NavLink to="moreinfo" activeClass="active" smooth={true} offset={-50}>More Info</NavLink></ListItem>
                        <ListItem><NavLink to="gifts" activeClass="active" smooth={true}>Gifts</NavLink></ListItem>
                        <ListItem><NavLink to="rsvp" activeClass="active" smooth={true} offset={-50}>RSVP</NavLink></ListItem>
                    </MobileListItems>
                </MobileTopNav>
            </MobileNavBackground> 
        </>

    )
}

export default Nav;
