import React from 'react';
import styled from 'styled-components/macro';
import Poster from '../../assets/images/dancing.gif';
import video from '../../assets/vids/dancing.mp4';

const FooterSection = styled.section`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;
`;

const VideoContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    transform: translate3d(0px, 0px, 0px);
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: #000;
`;

const Source = styled.source`
    filter: blur(1px);
`

const Footer = () => {

    return (
        <FooterSection>
            <VideoContainer>
                <Video autoPlay muted loop playsInline poster={Poster}>
                    <Source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </Video>
            </VideoContainer>
        </FooterSection>
    )
};

export default Footer;
