import React from 'react';
import styled from 'styled-components/macro';
import { SectionHeading, JumpToAnchor, InnerContainer } from '../../styles';
import { colors } from '../../styles/variables';

const SectionFaq = styled.section`
    position: relative;
    z-index: 3;
    background: #F8F7ED;
    overflow: hidden;
    min-height: 100vh;
    text-align: center;
    padding-bottom: 100px;
    font-family: 'Lexend', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Eyebrow = styled.p`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    padding-bottom: 10px;
    color: #000000;
`;

const TravelInfoWrapper = styled.div`
    display: flex;
    max-width: 1100px;
    margin: 50px;
    flex-wrap: wrap;
    justify-content: center;
`;

const ExternalHotelLink = styled.li`
    margin-bottom: 15px;
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: #000000;
    letter-spacing: 2px;
    text-decoration: none;
    border-bottom: 1px solid black;
    line-height: 22px;
`;

const FaqAnswer = styled.p`
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    border-bottom: 1px solid black;
    line-height: 22px;
    padding-bottom: 65px;
`

const FaqAnswerNoLine = styled.p`
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    line-height: 22px;
`

const TaxiListItem = styled.li`
    padding: 10px;
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    line-height: 22px; 
`

const TaxiList = styled.ul`
    padding: 10px;
`

const RSVPFaqAnswer = styled.p`
    font-family: 'Lexend', sans-serif;
    padding: 10px;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    border-bottom: 1px solid black;
    line-height: 22px;
    padding-bottom: 100px;

    span {
        color: ${colors.tan};
    }

    a {
        color: ${colors.tan};
    }
`

const Tel = styled.a`
    display: block;
    color: ${colors.tan};
`;

const Faq = () => {

    return (
        <>
            {/* eslint-disable-next-line */}
            <JumpToAnchor id="moreinfo"></JumpToAnchor>
                <SectionFaq>
                <InnerContainer>
                <Eyebrow>FAQs</Eyebrow>
                <SectionHeading>Other Important Info</SectionHeading>
                
                <TravelInfoWrapper>
                    
                        <ul>
                            <ExternalHotelLink>Is There Parking At The Venue?</ExternalHotelLink>
                            <FaqAnswer>Yep. Cars can be left at the venue overnight but must be collected between 9am-12pm the morning after the wedding. Any cars still left after 12pm the morning after the wedding will incur a £50 fine per car. Any cars left longer than that day will incur an additional charge on top of this fee.</FaqAnswer>
                            
                            <ExternalHotelLink>Getting There By Train</ExternalHotelLink>
                            <FaqAnswer>The nearest overground railway stations are Broxbourne and Harlow. The nearest tube station is Epping which is on the central line. All will require a taxi from the station to the venue.</FaqAnswer>

                            <ExternalHotelLink>Leaving The Venue</ExternalHotelLink>
                            <FaqAnswer>​​Everyone must be offsite by 12.30am (gates will be closed at this point and anyone remaining in the venue will be asked to walk to the end of the drive). Therefore please book a taxi before the day (see below).</FaqAnswer>

                            <ExternalHotelLink>Taxi's Home</ExternalHotelLink>
                            <FaqAnswerNoLine>
                                Apparently, it can take 45mins to an hour to order a taxi to The Barns at midnight, so best to order one in advance!<br/><br/>
                                
                                The venue recommend these local taxi companies:
                            </FaqAnswerNoLine>
                                <TaxiList>
                                    <TaxiListItem>Broxbourne Station Cars <Tel href="tel: +441992464007">01992 464007</Tel></TaxiListItem>
                                    <TaxiListItem>Acre Cars <Tel href="tel: +441992451111">01992 451111</Tel></TaxiListItem>
                                    <TaxiListItem>Harlow Cabs <Tel href="tel: +441279310562">01279 310562</Tel></TaxiListItem>
                                    <TaxiListItem>Lawlors Taxis Epping <Tel href="tel: +441992577888">01992 577888</Tel></TaxiListItem>
                                    <TaxiListItem>Black Cabs Harlow <Tel href="tel: +441279882888">01279 882888</Tel></TaxiListItem>
                                </TaxiList>
                            <FaqAnswer>  
                                The wedding venue can be a little tricky to find, so tell the taxi drivers that it's located on Epping Road near Epping Green, and the nearest postcode for sat nav is CM16 6PY. 
                                <br /><br />
                                Alternatively, if they put ‘The Barns at Lodge Farm’ into Google Maps or Apple Maps, this should bring them straight to the venue.
                            </FaqAnswer>
                                
                            <ExternalHotelLink>Does the venue allow confetti?</ExternalHotelLink>
                            <FaqAnswer>No, sorry. It's a working farm. <br /> But don’t worry we will be supplying biodegradable confetti.
                            </FaqAnswer>
                            {/* eslint-disable-next-line */}
                            <ExternalHotelLink id="rsvp">RSPV Date</ExternalHotelLink>
                            <RSVPFaqAnswer>Tell us asap, but at the latest <br/> <span>30<sup>th</sup> April 2022</span> <br/><br/> Please let us know any dietry requirements and RSVP to <br/><br/>Abi: <a href="tel:+447720383798">077 2038 3798</a><br/> OR<br/> Cara: <a href="tel:+4407873137476">078 7313 7476</a></RSVPFaqAnswer>

                        </ul>
        
                    
                </TravelInfoWrapper>
            
            </InnerContainer>
        </SectionFaq>
        </>
        
    )
};

export default Faq;
